import Trail from "../components/Trail";
import { useState } from "react";
import logo from "./../assets/images/logo.png";
import Typewriter from "typewriter-effect";

const Header = () => {
  const [open] = useState<boolean>(true);

  return (
    <header className="flex flex-col p-8">
      <div className="flex flex-col justify-center sm:flex-row">
        <img
          className="h-52 object-contain p-4"
          src={logo}
          alt="Rolf.Bible logo"
        />
        <div
          className="content-center p-4 text-center"
          // onClick={() => setOpen((state) => !state)}
        >
          <Trail open={open}>
            <span>Rolf</span>
            <span>.Bible</span>
          </Trail>
        </div>
      </div>

      <div className="h-20 text-center text-3xl font-bold text-white md:h-auto">
        <Typewriter
          options={{
            strings: [
              "COMING SOON!",
              "Software ontwikkelen voor Bijbelvertalers",
              "Totdat iedereen een Bijbel heeft!",
            ],
            autoStart: true,
            loop: true,
            delay: 50,
            deleteSpeed: 20,
          }}
        />
      </div>
    </header>
  );
};

export default Header;
