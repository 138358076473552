// import AboutMe from "./sections/AboutMe";
import Header from "./sections/Header";
// import AboutSoftware from "./sections/AboutSoftware";
import { NextUIProvider } from "@nextui-org/react";
// import Participate from "./sections/Participate";
// import TFC from "./sections/TFC";
// import Wycliffe from "./sections/Wycliffe";
// import Footer from "./sections/Footer";
// import Thermometer from "./sections/Thermometer";
import BiblePoverty from "./sections/BiblePoverty";

function App() {
  return (
    <NextUIProvider>
      <div className="flex min-h-screen flex-col items-center bg-gradient-to-tr from-teal-500 to-blue-600">
        <Header />
        <BiblePoverty />
        {/* <AboutMe />
        <AboutSoftware />
        <Thermometer />
        <TFC />
        <Wycliffe />
        <Participate />
        <Footer /> */}
      </div>
    </NextUIProvider>
  );
}

export default App;
