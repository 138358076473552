import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  CheckboxGroup,
  CircularProgress,
} from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import Globe from "react-globe.gl";
import secrets from "../assets/secrets.json";

const requestOptions: RequestInit = {
  method: "GET",
  redirect: "follow",
};

type PeopleGroup = {
  BibleStatus: number;
  Latitude: number;
  Longitude: number;
  NaturalName: string;
  Ctry: string;
  Population: number;
  PeopleID3: number;
  ROG3: string;
};

type GlobeData = {
  lat: number;
  lng: number;
  name: string;
  color: string;
  peopleId: number;
  ROG3: string;
};

const BiblePoverty = () => {
  const [jpData, setJpData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [filter, setFilter] = useState(["1", "2"]);
  const [temporaryFilter, setTemporaryFilter] = useState(["1", "2"]);
  const [showFilter, setShowFilter] = useState(false);
  const [languagesToGo, setLanguagesToGo] = useState(0);
  const [peopleToGo, setPeopleToGo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://joshuaproject.net/api/v2/people_groups?api_key=${secrets.joshua_project_api_key}&BibleStatus=1|2&limit=3000&fields=Population`,
        requestOptions,
      );
      const json = await response.json();

      if (json.status.status_code === 200) {
        setLanguagesToGo(json.data.length);
        let populationCount: number = 0;
        json.data.forEach((entry: { Population: number }) => {
          populationCount += entry.Population;
        });
        setPeopleToGo(populationCount.toString().slice(0, 2));
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (filter.length === 0) setJpData([]);
    setIsLoadingData(true);
    const bibleStatus = filter.join("|");
    const fetchData = async () => {
      const response = await fetch(
        `https://joshuaproject.net/api/v2/people_groups?api_key=${secrets.joshua_project_api_key}&BibleStatus=${bibleStatus}&limit=2500&fields=NaturalName|Ctry|Population|Longitude|Latitude|BibleStatus|PeopleID3|ROG3&page=1`,
        requestOptions,
      );
      const json = await response.json();

      if (json.status.status_code === 200) setJpData(json.data);
    };

    fetchData().catch(console.error);
  }, [filter]);

  const getColor = (bibleStatus: number): string => {
    if (bibleStatus === 1) return "#f31260";
    else if (bibleStatus === 2) return "#f5a524";
    else if (bibleStatus === 3) return "#5c6ac4";
    else if (bibleStatus === 4) return "#17c964";
    throw new Error(`Unexpected BibleStatus: ${bibleStatus}`);
  };

  const globeData: GlobeData[] = useMemo(() => {
    setIsLoadingData(false);
    if (jpData.length === 0) return [];
    return jpData.map((peopleGroup: PeopleGroup) => ({
      lat: peopleGroup.Latitude,
      lng: peopleGroup.Longitude,
      name: `${peopleGroup.NaturalName} (${peopleGroup.Ctry})`,
      color: getColor(peopleGroup.BibleStatus),
      peopleId: peopleGroup.PeopleID3,
      ROG3: peopleGroup.ROG3,
    }));
  }, [jpData]);

  const goToJoshuaProjectPage = (dataPoint: GlobeData) => {
    window.open(
      `https://joshuaproject.net/people_groups/${dataPoint.peopleId}/${dataPoint.ROG3}`,
      "_blank",
    );
  };

  return (
    <Card className="no-scrollbar mb-10 w-11/12 lg:w-8/12 xl:w-7/12 2xl:w-6/12">
      {languagesToGo > 0 ? (
        <>
          <CardBody>
            <div className="flex flex-col-reverse items-center justify-between md:flex-row">
              <div className="flex items-center justify-center caret-transparent">
                {isLoadingData || globeData.length === 0 ? (
                  <CircularProgress
                    className="flex h-96 w-96 items-center justify-center"
                    label="Laden..."
                  />
                ) : (
                  <div className="flex flex-col items-center">
                    <Globe
                      globeImageUrl="//unpkg.com/three-globe/example/img/earth-day.jpg"
                      backgroundColor="#ffffff"
                      width={320}
                      height={320}
                      pointsData={globeData}
                      pointColor={"color"}
                      pointAltitude={0.01}
                      pointRadius={0.5}
                      onPointClick={(point) =>
                        goToJoshuaProjectPage(point as GlobeData)
                      }
                    />
                    <Button
                      color={showFilter ? "default" : "primary"}
                      className="mb-2"
                      onClick={() => {
                        setShowFilter(!showFilter);
                      }}
                    >
                      Filter
                    </Button>
                    {showFilter && (
                      <>
                        <CheckboxGroup
                          className="text-center"
                          label="Beschikbaarheid van de Bijbel"
                          value={temporaryFilter}
                          onChange={(value) => {
                            setTemporaryFilter(value as string[]);
                          }}
                        >
                          <Checkbox color="danger" value="1">
                            Helemaal niets
                          </Checkbox>
                          <Checkbox color="warning" value="2">
                            Stukjes
                          </Checkbox>
                          <Checkbox color="primary" value="3">
                            Nieuwe Testament
                          </Checkbox>
                          <Checkbox color="success" value="4">
                            Hele Bijbel
                          </Checkbox>
                        </CheckboxGroup>
                        {JSON.stringify(filter) !==
                          JSON.stringify(temporaryFilter) && (
                          <Button
                            className="mt-2"
                            color="primary"
                            onClick={() => {
                              setFilter(temporaryFilter);
                              setShowFilter(false);
                            }}
                          >
                            Toepassen
                          </Button>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="m-4 inline-block align-middle">
                {" "}
                <h4 className="text-large font-bold">{`Nog ruim ${peopleToGo} miljoen mensen wachten op Gods Woord in de taal van hun hart!`}</h4>
                <p className="font-bold">{`Er zijn nog ${languagesToGo} talen die geen Bijbel hebben`}</p>
              </div>
            </div>
          </CardBody>
          <a href="https://www.joshuaproject.net">
            <CardFooter className="flex justify-center">
              Deze data is gebaseerd op informatie van Joshua Project
            </CardFooter>
          </a>
        </>
      ) : (
        <CardBody className="flex items-center">
          <CircularProgress label="Voortgang Bijbelvertaalwerk Laden..." />
        </CardBody>
      )}
    </Card>
  );
};

export default BiblePoverty;
